import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';
import {
  CLP_ACTION,
  ExpiredPassword,
  InitJumpLoginFlow,
  InitValidatePwdFlow,
  LOGIN_ACTION,
  MFA,
  MFA_ID,
  OtpNeeded,
  OtpNeededImpersonate,
  OttNeededImpersonate,
  PhoneNumberNeeded,
  ValidationCodeNeeded
} from '@seco/login';
import { AlertService } from '../services/alert/alert.service';
import { AlertModel, AlertType, Flow } from '../model/alert.model';
import { Store } from '@ngrx/store';
import { CoreState } from './core.reducer';
import { updateTitle, updateFormAlert } from './core.actions';
import { TitleInformation, TitleState } from '../model/title-information';

@Injectable()
export class CoreEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly alertService: AlertService,
    private readonly store: Store<CoreState>
  ) {}

  /**
   * Listen OTP action from @seco/login, get payload and display warning message
   */
  otpViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.OTP_NEEDED),
        tap((data: OtpNeeded) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTP,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  totpViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.TOTP_NEEDED),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.authenticatorApp:Authenticator app`,
            subtitle: '',
            titleState: TitleState.login
          });
        })
      ),
    { dispatch: false }
  );

  otpJumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.JUMP_LOGIN_OTP_NEEDED),
        tap((data: OtpNeededImpersonate) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTP,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  ottJumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.JUMP_LOGIN_OTT_NEEDED),
        tap((data: OttNeededImpersonate) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTT,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  clpApiErrorListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CLP_ACTION.ERROR_RECEIVED),
        tap((data: any) => {
          const message = data.payload[0]?.error?.message;
          if (message && message.toUpperCase().includes('CLP')) {
            const alert: AlertModel = {
              type: AlertType.ERROR,
              data: message
            };
            this.alertService.addAlert(alert);
          }
        })
      ),
    { dispatch: false }
  );

  forgetPwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_FORGET_PWD_FLOW),
        tap((data: any) => {
          this.updateTitle({
            title: $localize`:@@newlogin.resetPwd.title:Reset your password`,
            subtitle: data.subtitle,
            titleState: TitleState.forgetPwd
          });
        })
      ),
    { dispatch: false }
  );

  jumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_JUMP_LOGIN_FLOW),
        tap((data: InitJumpLoginFlow) => {
          this.updateTitle({
            title: $localize`:@@newlogin.jumpLogin.title:Log in`,
            subtitle: data.payload ?? '',
            titleState: TitleState.jumpLogin
          });
        })
      ),
    { dispatch: false }
  );

  validatePwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_VALIDATE_PWD_FLOW),
        tap((data: InitValidatePwdFlow) => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.confirm:Confirm new password`,
            subtitle: data.payload ?? '',
            titleState: TitleState.validatePwd
          });
        })
      ),
    { dispatch: false }
  );

  validatePwdResetViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.VALIDATE_PWD_END),
        tap(() => {
          const alert: AlertModel = {
            type: AlertType.SUCCESS,
            message: $localize`:@@newlogin.login.validatePwd.success:Your password was reset successfully`,
            state: Flow.MESSAGE
          };
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: '',
            titleState: TitleState.login
          });
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  expiredPwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.EXPIRED_PASSWORD),
        tap((data: ExpiredPassword) => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.expiredPwd:Expired password`,
            subtitle: data.payload ?? '',
            titleState: TitleState.expiredPwd
          });
        })
      ),
    { dispatch: false }
  );

  ddnaViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.DDNA_NEEDED),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: $localize`:@@newlogin.ddna.subtitle:Register a Digital DNA (DDNA)`,
            titleState: TitleState.ddna
          });
        })
      ),
    { dispatch: false }
  );

  backToLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.CHANGE_ACCOUNT),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: '',
            titleState: TitleState.login
          });
        })
      ),
    { dispatch: false }
  );

  formErrorListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOGIN_ACTION.FORM_ERROR),
      map((data: any) => updateFormAlert(data.payload))
    )
  );

  phoneNumberNeededListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.PHONE_NUMBER_NEEDED),
        tap((data: PhoneNumberNeeded) => {
          if (data.payload && typeof data.payload !== 'string' && data.payload.mfaSelfRegistrationActivated) {
            this.updateTitle({
              title: this.getPhoneTitle(
                data.payload.mfaSelfRegistrationActivated ?? false,
                data.payload.selectedMFA ?? undefined
              ),
              subtitle: '',
              titleState: TitleState.login
            });
          }
        })
      ),
    { dispatch: false }
  );

  phoneValidationCodeNeededListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.VALIDATION_CODE_NEEDED),
        tap((data: ValidationCodeNeeded) => {
          if (data.payload && typeof data.payload !== 'string' && data.payload.mfaSelfRegistrationActivated) {
            this.updateTitle({
              title: this.getPhoneTitle(
                data.payload.mfaSelfRegistrationActivated ?? false,
                data.payload.selectedMFA ?? undefined
              ),
              subtitle: '',
              titleState: TitleState.login
            });
          }
        })
      ),
    { dispatch: false }
  );

  selectMFAViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.MFA_SELF_SELECTION),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.selectedMFA.title:Verify your identity`,
            subtitle: '',
            titleState: TitleState.mfa
          });
        })
      ),
    { dispatch: false }
  );

  selectTotpRegistrationListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.AUTHENTICATOR_APP_REQUEST),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.totpRegistration.title:Authenticator app`,
            subtitle: '',
            titleState: TitleState.mfaTotp
          });
        })
      ),
    { dispatch: false }
  );

  private updateTitle(titleInformation: TitleInformation) {
    this.store.dispatch(updateTitle(titleInformation));
  }

  private getPhoneTitle(mfaSelfRegistrationActivated: boolean, selectedMFA: MFA | undefined) {
    if (mfaSelfRegistrationActivated) {
      if (selectedMFA?.id === MFA_ID.OTP_SMS) {
        return $localize`:@@newlogin.login.phone.type.sms.title:SMS`;
      } else if (selectedMFA?.id === MFA_ID.OTP_VOICE_CALL) {
        return $localize`:@@newlogin.login.phone.type.voice.title:Voice call`;
      }
    }
    return $localize`:@@newlogin.login.signIn:Sign in`;
  }
}
